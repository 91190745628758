import { utils } from "@/libs-core/utils.min.js";
export default {
    props: ["linkAppShare"],
    methods: {
        copyLink() {
            utils.copyToClipboard("#link-share-app");
        }
    },
    mounted() {
        let cssItemShare =
            "width: 56px;height: 56px;margin-bottom: 12px !important;border-radius: 4px;font-size: 30px;color: #ffffff;cursor: pointer;outline: none;";

        $("#modal-share-app .items-share").each(function(index, item) {
            let styleAttr = $(item).attr("style") + cssItemShare;
            if (index === 5 || index === 11) {
                styleAttr += "margin-right: 0 !important;";
            } else {
                styleAttr += "margin-right: 13px !important;";
            }
            $(item).attr("style", styleAttr);
        });
    }
};
