import { dashboardSV } from "../../../views/dashboard/dashboard.service";

export default {
    props: ["dataApp"],
    data() {
        return {
            dataAppObject: {}
        };
    },
    watch: {
        dataApp(val) {
            this.dataAppObject = val;
        }
    },
    methods: {
        openApp() {
            let app = this.dataAppObject;
            if (this.dataApp && this.dataApp.category && this.dataApp.category.length > 0 && app.description) {
                console.log("app", app.clone_from);
                
                if (JSON.parse(app.description)) {
                    window.open(JSON.parse(app.description).linkDemo, "_blank");
                } else {
                    window.open("https://app.appon.vn/" + app.clone_from, "_blank");
                }
            } else {
                window.open("https://app.appon.vn/" + app.id, "_blank");
            }
        },
        shareApp() {
            let id = this.dataAppObject.id;
            this.$emit("shareApp", id);
        },
        cloneApp() {
            let app = this.dataAppObject;
            if (!app.category) {
                this.$emit("emitCloneApp", app);
            } else {
                console.log('template')
                this.$emit("emitCloneAppTemplate", app);
            }
        },
        deleteApp() {
            const thisVue = this;
            let dataApp = this.dataAppObject;
            let data = {
                id: dataApp.id
            };
            this.$swal({
                title: "",
                text: "You won't be able to revert this action.",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Remove"
            }).then((result) => {
                if (result.value) {
                    dashboardSV.removePage(JSON.stringify(data), function(data) {
                        localStorage.removeItem(`#model-new-clone-page-${data.data[0].id}`);
                        console.log("delete: ", data);
                        thisVue.$emit("deleteItemPage", data);
                    });
                }
            });
        },
        editApp() {
            let app = this.dataAppObject;
            if (!app.category) this.$emit("editItemPage", app.id);
            else this.$emit("emitCloneAppTemplate", app);
        },
        handleClickAction(type) {
            let selector;
            if ($(".list-app-section.see-all").css("display") === "none") {
                selector = `.list-app-section #${this.dataAppObject.id} .overlay `;
            } else {
                selector = `.list-app-section.see-all #${this.dataAppObject.id} .overlay `;
            }
            switch (type) {
                case "close":
                    $(selector + ".action").hide();
                    $(selector).removeClass("show-overlay");
                    break;
                case "open":
                    $(selector + ".action").css("display", "flex");
                    $(selector).addClass("show-overlay");
                    break;
                case "copy":
                    this.cloneApp();
                    this.handleClickAction("close");
                    break;
                case "share":
                    this.shareApp();
                    this.handleClickAction("close");
                    break;
                case "delete":
                    this.deleteApp();
                    this.handleClickAction("close");
                    break;
            }
        },
        changeNameApp: function(val) {
            if (!val.category) {
                dashboardSV.updateSource(
                    JSON.stringify({
                      name: val.name,
                      id: val.id
                    }),
                    function() {}
                  );
            }
            console.log(val);
        },
        addMemberApp: function(val) {
            this.$emit("addMemberApp", val);
        }
    },
    mounted() {
        this.dataAppObject = this.dataApp;
        console.log("dataAppObject:: ", this.dataAppObject);
    }
};
